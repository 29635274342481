import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import CallButton from "../components/callButton"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { slugify } from "../helpers"

const ArticlesList = (props) => {

  const data = useStaticQuery(graphql`
    query {
      allContentfulArticle {
        edges {
          node {
            content {
              childMarkdownRemark {
                html
              }
            }
            id
            title
            cover {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const articles = data.allContentfulArticle.edges
  return (
    <Layout>
      <SEO title="Articles" />
      <div className="mx-auto container font-sans flex flex-col items-center justify-around">
        <h2 className="text-center uppercase text-xl text-navy-500 font-thin mt-4 mb-4 lg:mt-8">Clinical Information</h2>
        <p className="max-w-3xl text-center mx-auto text-navy-500 px-4 mb-6 lg:mb-8" >
          At U & M Family EyeCare, we feel a great responsibility to educate our patients about their eyes and visual system. Below are some common concerns patients express about their visual health. Please feel free to speak to your doctor regarding any questions you may have about your eye health or that of a family member. You may contact us via email about general questions as well.
        </p>
        <div className=" flex justify-center mb-6 lg:mb-8"><CallButton /></div>
        <div className="flex flex-wrap items-center justify-center ">
          {articles.map(({ node: article }) => {
            return <Link to={'/articles/' + slugify(article.title)}
              key={article.id}
              aria-label={article.name + ' U & M Family Eye Care'}
              className="mx-0 text-center text-navy-600 lg:mb-6 mb-10 w-full lg:max-w-md lg:min-w-md lg:mx-4">
                <Img fluid={article.cover.localFile.childImageSharp.fluid} className="h-64 lg:rounded mx-auto" />
              <div className="font-bold text-lg py-2 h-20">{article.title}</div>
            </Link>
          })}
        </div>
      </div>
    </Layout>
  )
}

export default ArticlesList